<section class="header desktop-visibility-flex">
    <div class="container">
        <div class="row no-gutter align-items-center">
            <div class="col-lg-4 col-md-12 links">
                <ul>
                    <li (click)="goToResources()" class="resources"><a>Resources & FAQ</a></li>
                    <li (click)="goToAboutUs()" class="aboutUs"><a>About Us</a></li>
                </ul>
            </div>
            <div class="col-lg-4 col-md-12 logo-wrap">
                <a (click)="onHome()">
                    <ng-container *ngIf="homeActive"><img src="./assets/images/logo.svg"></ng-container>
                    <ng-container *ngIf="!homeActive"><img class="newLogo"
                            src="./assets/images/logo_new.png"></ng-container>
                </a>
            </div>
            <div class="col-lg-4 col-md-12 buttons d-flex justify-content-end" *ngIf="!isLogin">
                <button class="btn btn-primary btn-block" (click)="onLogin()">LOG IN</button>
                <button class="btn btn-primary btn-block signUp" (click)="OnSignUp()">SIGN UP</button>
            </div>
            <div class="col-lg-4 col-md-12 d-flex justify-content-end align-items-center" *ngIf="isLogin">
                <div class="notification" data-bs-toggle="dropdown" aria-expanded="false"
                    [ngClass]="{'notification-no-data': !notificatonMessages.length}">
                    <div [ngClass]="!notificatonMessages.length ? 'cp-n' : ''">
                        <img src="./assets/images/notification.svg" alt="Notification" href="#" id="notificationList"
                            role="button">
                        <!-- <img *ngIf="!notificatonMessages.length" src="./assets/images/notification.svg" alt="Notification" class="no-data-inside"> -->
                        <span *ngIf="notificationCount > 0"
                            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {{notificationCount}}
                            <span class="visually-hidden">unread messages</span>
                        </span>
                    </div>
                    <ul class="dropdown-menu" aria-labelledby="notificationList" *ngIf="notificatonMessages.length">
                        <li *ngFor="let item of notificatonMessages.slice(0,5)">
                            <a class="dropdown-item noti-dropdown-item" (click)="goToPage()">{{item['title']}}</a>
                        </li>
                        <li *ngIf="notificatonMessages.length > 5">
                            <a class="dropdown-item view-all" (click)="goToPage()">View All</a>
                        </li>
                    </ul>
                </div>
                <div class="user-img">
                    <img src="./assets/images/user.svg" alt="User">
                </div>
                <div class="user-detail">
                    <p>Welcome!</p>
                    <a class="nav-link dropdown-toggle userName" href="#" id="navbarDropdown" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        {{userName}}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" href="/my-profile">My Profile</a></li>
                        <!-- <li><a class="dropdown-item" href="/my-address">My Address</a></li> -->
                        <li><a class="dropdown-item" href="/my-form-status">My Forms in Progress</a></li>
                        <li><a class="dropdown-item" href="/my-files">My Completed Documents</a></li>
                        <li><a class="dropdown-item" href="/shared-doc">Documents Shared with Me</a></li>
                        <li><a class="dropdown-item" href="/change-password">Change Password</a></li>
                        <li><a class="dropdown-item"  (click)="closeMenuModal()">Survey</a></li>
                        <li><a class="dropdown-item" (click)="logoutClicked()">Logout</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="header mobile-visibility-flex">
    <div class="container">
        <div class="mobile-header">
            <div class="menu-item" (click)="menuCall()">
                <!-- <img src="./assets/images/menu.svg" alt="menu"> -->
                <span class="menu-label">&nbsp;Menu</span>
            </div>
            <div class="logo-wrap">
                <a (click)="onHome()">
                    <!-- <img src="./assets/images/mobile-logo.webp"> -->
                    <img src="./assets/images/mobile-logo.jpg">
                </a>
            </div>
            <div class="icon-wrap" [ngStyle]="{'visibility': isLogin ? 'hidden' : 'visible'}">
                <a *ngIf="!isLogin" (click)="onLogin()">
                    <img src="./assets/images/user.svg" alt="User">
                </a>
                <!-- <a *ngIf="isLogin" data-bs-toggle="modal" data-bs-target="#navModal" (click)="navCall()">
                    <img src="./assets/images/user.svg" alt="User">
                </a> -->
            </div>
        </div>
    </div>
</section>


<button style="display: none;" data-bs-toggle="modal" data-bs-target="#menuModal"
    class="btn btn-primary btn-block border-button" #openMenuModal></button>
<div class="modal fade" id="menuModal" tabindex="-1" aria-labelledby="menuModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <img src='./assets/images/cross_blue.svg' #menuModalClose class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <div class="logo-wrap">
                    <a>
                        <!-- <img src="./assets/images/mobile-logo.webp"> -->
                        <img src="./assets/images/mobile-logo.jpg">
                    </a>
                </div>
            </div>
            <div class="modal-body">
                <div class="nav-col mobile-visibility mobile-nav">
                    <nav class="nav flex-column mb-4" id="navigation">
                        <a class="nav-link resources-nav-link" href="/resources">Resources</a>
                        <a class="nav-link about-us-nav-link" href="/about-us">About Us</a>
                        <a *ngIf="isLogin" class="nav-link profile-nav-link" href="/my-profile">My Profile</a>
                        <!-- <a class="nav-link address-nav-link" href="/my-address">My Address</a> -->
                        <a *ngIf="isLogin" class="nav-link forms-nav-link" href="/my-form-status">My Forms in
                            Progress</a>
                        <a *ngIf="isLogin" class="nav-link file-nav-link" href="/my-files">My Completed Documents</a>
                        <a *ngIf="isLogin" class="nav-link shared-nav-link" href="/shared-doc">Documents Shared with
                            Me</a>
                        <a *ngIf="isLogin" class="nav-link change-password-nav-link" href="/change-password">Change
                            Password</a>
                        <a *ngIf="isLogin" class="nav-link"  (click)="closeMenuModal()">Survey</a>
                        <a *ngIf="isLogin" class="nav-link" (click)="logoutClicked()">Logout</a>
                    </nav>
                    <!-- <nav class="nav flex-column mb-4" id="navigation">
                                               
                </nav> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="navModal" tabindex="-1" aria-labelledby="logoutModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <div class="left-side">
                    <img src="./assets/images/cross_blue.svg" alt="close" data-bs-dismiss="modal" aria-label="Close">
                    <div class="name-block">
                        <span class="welcome">Welcome!</span>
                        <span class="name">{{userName}}</span>
                    </div>
                </div>
                <div class="right-side">
                    <!-- <div class="notify">
                            <img src="./assets/images/notification.svg">
                    </div> -->
                    <div class="notification" [ngClass]="{'notification-no-data': !notificatonMessages.length}">
                        <div data-bs-toggle="dropdown" aria-expanded="false"
                            [ngClass]="!notificatonMessages.length ? 'cp-n' : ''">
                            <img src="./assets/images/notification.svg" alt="Notification" href="#"
                                id="notificationList" role="button">
                            <!-- <img *ngIf="!notificatonMessages.length" src="./assets/images/notification.svg" alt="Notification" class="no-data-inside"> -->
                            <span *ngIf="notificationCount > 0"
                                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {{notificationCount}}
                                <span class="visually-hidden">unread messages</span>
                            </span>
                        </div>
                        <ul class="dropdown-menu" aria-labelledby="notificationList" *ngIf="notificatonMessages.length">
                            <li *ngFor="let item of notificatonMessages.slice(0,5)">
                                <a class="dropdown-item noti-dropdown-item" (click)="goToPage()" data-bs-dismiss="modal"
                                    aria-label="Close">{{item['title']}}</a>
                            </li>
                            <li *ngIf="notificatonMessages.length > 5">
                                <a class="dropdown-item view-all" data-bs-dismiss="modal" aria-label="Close"
                                    (click)="goToPage()">View All</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <img src="./assets/images/cross_blue.svg" alt="" data-bs-dismiss="modal" aria-label="Close"> -->
                <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
            </div>
            <div class="modal-body">
                <div class="nav-col mobile-visibility mobile-nav">
                    <nav class="nav flex-column mb-4" id="navigation">
                        <a class="nav-link profile-nav-link" href="/my-profile">My Profile</a>
                        <!-- <a class="nav-link address-nav-link" href="/my-address">My Address</a> -->
                        <a class="nav-link forms-nav-link" href="/my-form-status">My Forms in Progress</a>
                        <a class="nav-link file-nav-link" href="/my-files">My Completed Documents</a>
                        <a class="nav-link shared-nav-link" href="/shared-doc">Documents Shared with Me</a>
                        <a class="nav-link change-password-nav-link" href="/change-password">Change Password</a>
                        <a class="nav-link" (click)="closeMenuModal()">Survey</a>
                        <a class="nav-link" (click)="logoutClicked()">Logout</a>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

