import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { CommonService } from './services/common.service';
import { Router, NavigationEnd } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'relevant-redio-web';

    constructor(
        @Inject(DOCUMENT) private document: any,
        private commonService: CommonService,
        private router: Router,
        private meta: Meta,
        private titleService: Title,
    ) { }


    ngOnInit(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.setMetaTags();
            });
    }

    setMetaTags() {
        // const currentUrl = this.router.url;

        // if (currentUrl === '/will') {
        const title = this.getTitle(
            this.router.routerState,
            this.router.routerState.root
        );


        if (title && title.length) {
            this.titleService.setTitle(title[title.length - 1].title);

            this.meta.updateTag({
                property: "og:title",
                content: title[title.length - 1].title,
            });
            this.meta.updateTag({ name: 'description', content: title[title.length - 1].desc });
            this.meta.updateTag({
                property: "og:description",
                content: title[title.length - 1].desc,
            });
        }

        // }
    }

    public getTitle(state, parent) {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push({ title: parent.snapshot.data.title, desc: parent.snapshot.data.description });
        }

        if (state && parent) {
            data.push(... this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }

}
