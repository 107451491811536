<div *ngIf="!isServer">
    <router-outlet></router-outlet>
    <button style="display: none;" data-bs-toggle="modal" #openLogoutModal data-bs-target="#logoutModal">Logout</button> 
    <div  class="modal fade" id="logoutModal" tabindex="-1" aria-labelledby="logoutModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
                <button type="button" #cancelLogoutModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title" id="logoutModalLabel">Are you sure?</h5>
                You want to logout
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><b>Cancel</b></button>
              <button type="button" class="btn btn-primary" (click)="OnLogout()"><b>Ok</b></button>
            </div>
          </div>
        </div>
    </div>
</div>
<!-- //Comment added -->
<ng-template *ngIf="isServer">
    <app-loader></app-loader>
</ng-template>


<button style="display: none;" data-bs-toggle="modal" data-bs-target="#surveyModal"
    class="btn btn-primary btn-block border-button" #openSurveyModal></button>
<div class="modal fade zIndex" id="surveyModal" tabindex="-1" data-bs-keyboard="false" data-bs-backdrop="static" aria-labelledby="surveyModalLabel" aria-hidden="true"
(shown.bs.modal)="onModalShown()" (hidden.bs.modal)="onModalHidden()">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <app-survey-form [isVisible]="isModalActive"></app-survey-form>
      </div>
    </div>
</div>