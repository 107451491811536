import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api-data.service';
import { URLS } from '../../_config/api.config';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommonService } from 'src/app/services/common.service';
import { DOCUMENT } from '@angular/common';


@Component({
    selector: 'app-sideNav',
    templateUrl: './sideNav.component.html',
    styleUrls: ['./sideNav.component.scss'],
})
export class SideNavComponent implements OnInit {
    @ViewChild('cancelLogoutModal') cancelLogoutModal;

    private subscription: Subscription = new Subscription();
    public title: any;
    callType: string;
    isLoggingOut = false;
    activeClass = '';
    isModalActive = false;
    @ViewChild('surveyModal') surveyModal!: ElementRef;

    constructor(
        private router: Router,
        private dataService: ApiService,
        private toastr: ToastrService,
        private authService: AuthenticationService,
        private commonService: CommonService,
        @Inject(DOCUMENT) private document: any,
    ) { }

    ngOnInit() {

        this.setActiveClass(this.router.url); // Initial activation

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.setActiveClass(event.url);
            }
        });
    }

    ngAfterViewInit() {
        const modalElement = document.getElementById('surveyModal');
        if (modalElement) {
            modalElement.addEventListener('shown.bs.modal', () => {
                this.onModalShown();
            });
            modalElement.addEventListener('hidden.bs.modal', () => {
                this.onModalHidden();
            });
        }
    }

    setActiveClass(url?: string) {
        if (url === '/my-profile') {
            // var elems = this.document.getElementsByClassName('profile')[0];
            // if (elems != null) {
            //     elems.classList.remove("active");
            // }
            // elems.className = "active";
            this.activeClass = 'profile';
        }
        if (url === '/my-address') {
            // var elems = this.document.getElementsByClassName('address')[0];
            // if (elems != null) {
            //     elems.classList.remove("active");
            // }
            // elems.className = "active";
            this.activeClass = 'address';
        }
        if (url === '/my-form-status') {
            // var elems = this.document.getElementsByClassName('forms')[0];
            // if (elems != null) {
            //     elems.classList.remove("active");
            // }
            // elems.className = "active";
            this.activeClass = 'forms';
        }
        if (url === '/my-files') {
            // var elems = this.document.getElementsByClassName('file')[0];
            // if (elems != null) {
            //     elems.classList.remove("active");
            // }
            // elems.className = "active";
            this.activeClass = 'file';
        }
        if (url === '/shared-doc') {
            // var elems = this.document.getElementsByClassName('documents')[0];
            // if (elems != null) {
            //     elems.classList.remove("active");
            // }
            // elems.className = "active";
            this.activeClass = 'documents';
        }
        if (url === '/change-password') {
            // var elems = this.document.getElementsByClassName('change-password')[0];
            // if (elems != null) {
            //     elems.classList.remove("active");
            // }
            // elems.className = "active";
            this.activeClass = 'change-password';
        }
    }

    // notifyMe(type) {
    //     if (type === "menuCall") {
    //         this.callType = "menuCall";
    //     }
    //     if (type === "menuCall") {
    //         this.callType = "navCall";
    //     }
    //     setTimeout(() => {
    //         if(this.router.url === '/resources'){
    //             var elems = this.document.getElementsByClassName('resources-nav-link')[0];
    //             if(elems != null) {
    //                 elems.classList.remove("active");
    //             }
    //             elems.className = "resources-nav-link active";
    //         }
    //         if(this.router.url === '/about-us'){
    //             var elems = this.document.getElementsByClassName('about-us-nav-link')[0];
    //             if(elems != null) {
    //                 elems.classList.remove("active");
    //             }
    //             elems.className = "about-us-nav-link active";
    //         }
    //     }, 100);
    // }

    OnLogout() {
        if (!this.isLoggingOut) {
            this.isLoggingOut = true;
            this.subscription.add(
                this.dataService.get(URLS.logout).subscribe(data => {
                    if (data['code'] == 200) {
                        this.commonService.localStorageClear();
                        if (data['code'] == 200) {
                            this.toastr.success('Success', data['message']);
                        }
                        this.authService.logout();
                        this.cancelLogoutModal.nativeElement.click();
                        sessionStorage.clear();
                        this.router.navigate(['/home']);
                    } else {
                        this.toastr.error(data['message'], "Error");
                    }
                }, error => {
                    this.toastr.error(error['message'], "Error");
                    this.commonService.localStorageClear();
                    this.router.navigate(['/home']);
                })
            )
        }
    }

    onModalShown() {
        this.activeClass = 'survey';
        this.isModalActive = true;
    }

    onModalHidden() {
        this.isModalActive = false;
        this.setActiveClass(this.router.url);
    }

}
