import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-creating-will',
  templateUrl: './creating-will.component.html',
  styleUrls: ['./creating-will.component.scss']
})
export class CreatingWillComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  gotoWillForm() {

    const userId = localStorage.getItem('userId')
    if (!userId) {
        sessionStorage.setItem('formId', '6490049fe7bc6b6649320fe5');
    }
    localStorage.setItem('my-form-status-id', '6490049fe7bc6b6649320fe5');
    this.router.navigate(['my-forms']);
}
}
