import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    constructor(
        private httpClient: HttpClient
    ) {

    }


    get(url: string, params?: any, token?: any): Observable<any> {
        let obj = {}
        if (token) {
            obj['authorization'] = token
        }
        return this.httpClient.get(`${url}`, { params: params, headers: new HttpHeaders(obj), withCredentials: true });
    }

    post(url: string, param?: any, queryParams?: any): Observable<any> {
        return this.httpClient.post(`${url}`, param, { params: queryParams, withCredentials: true });
    }

    put(url: string, data?: any): Observable<any> {
        const options = {
            withCredentials: true
        };
        return this.httpClient.put(`${url}`, data, options);
    }

    patch(url: string, data?: any): Observable<any> {
        const options = {
            withCredentials: true
        };
        return this.httpClient.patch(`${url}`, data, options);
    }

    delete(url: string, data?: any): Observable<any> {
        const options = {
            withCredentials: true,
            body: data  // The body property can be used to send data with the DELETE request
        };
        return this.httpClient.delete(`${url}`, options);
    }


}
